$(function() {
    let $loading = $('.loading');
    let $loading_wrap = $('.loading-wrap');
    let $wrap = $('.loading-flag');
    let isLoaded = false;
    let $popup = $('.popup');
    let $popupClose = $('.popup__close');

    $(window).on('load',function () { //全ての読み込みが完了したら実行
        stopload();
        isLoaded = true;
    });

    //10秒たったら強制的にロード画面を非表示
    setTimeout(function(){
        stopload();
        isLoaded = true;
    }, 100000);

    function stopload() {
        if (isLoaded) return;
        $loading_wrap.delay(2000).fadeOut(800);
        $loading.delay(3200).fadeOut(800);
        $wrap.addClass('active');
        setTimeout(function(){
            $popup.addClass('active');
        }, 4000);
    }

    $.fn.aemOneTime('superfly-loading', function () {
        // 初回
    }, function () {
        // 2回目はローディングを表示したくないのですぐ削除
        $loading.remove();
        $wrap.addClass('active');
        setTimeout(function(){
            $popup.addClass('active');
        }, 1500);
        isLoaded = true;
    });
    $popupClose.on('click', function () {
        $popup.removeClass('active');
    }
});

$(function () {
    $('.js-accordion').aemAccordion({
        speed: 200, // スピード
        easing: 'linear', // イージング
    });

    $('.js-totop').on('click',function() {
        $('.outer__inner').animate({ scrollTop: 0 }, 500);
        return false;
    });

    $('.js-tc-target').aemToggleClass();
});

$(function () {
    /**
     * overflow: scroll; でwindowのscrollが上手く取れないのでinviewの処理を自作する
     */

    interface InviewOptions {
        offset: string;
        addClassName: string;
        scrollEnd: boolean;
    }

    /**
     * Inview Class
     */
    class Inview {
        $target: JQuery<HTMLElement>
        $parent: JQuery<HTMLElement>
        eventFirePosition: number;
        setEventPositionTimer: number;
        resizeTimer: number;
        options: InviewOptions;

        constructor(target: JQuery<HTMLElement>, $parent: JQuery<HTMLElement>) {
            this.$target = $(target);
            this.$parent = $parent;
            this.eventFirePosition = 0;
            this.setEventPositionTimer = 0;
            this.resizeTimer = 0;

            // デフォルトの設定
            const options: InviewOptions = {
                offset: "0",
                addClassName: 'js-active',
                scrollEnd: false
            }

            // data属性で値が設定されていたらそれを使う
            const tmpOptions = this.$target.data('inview');
            if (tmpOptions && typeof tmpOptions === 'object') {
                $.extend(options, tmpOptions);
            }

            this.options = options;

            this.init();
        }

        /**
         * 初期化処理。イベントの設定など
         */
        init() {
            this.setEventPoition();
            this.fire();
            this.setEventPositionTimer = setInterval(() => this.setEventPoition(), 250);
            $base.on('scroll', this.scrollHandle.bind(this));
            $base.on('resize', this.resizeHandle.bind(this));
        }

        /**
         * 処理の削除。表示されたら不要なイベントなどは全部消す
         */
        destroy() {
            $base.off('scroll', this.scrollHandle.bind(this));
            $base.off('resize', this.resizeHandle.bind(this));
            clearInterval(this.setEventPositionTimer);
            clearTimeout(this.resizeTimer);
        }

        /**
         * イベント発火位置を設定するメソッド
         */
        setEventPoition() {
            if (this.options.offset.indexOf('%') != -1) {
                let par = parseInt(this.options.offset.replace('%', ''));
                let offset = window.innerHeight * (par / 100);
                this.eventFirePosition = this.$target[0].getBoundingClientRect().top - window.innerHeight + offset + this.$parent.scrollTop();
            } else if (this.options.offset.indexOf('px') != -1) {
                this.eventFirePosition = this.$target[0].getBoundingClientRect().top - window.innerHeight + this.$parent.scrollTop() + parseInt(this.options.offset.replace('px', ''));
            } else {
                this.eventFirePosition = this.$target[0].getBoundingClientRect().top - window.innerHeight + this.$parent.scrollTop() + parseInt(this.options.offset);
            }
        }

        /**
         * イベント発火しているか確認するメソッド
         */
        fire() {
            if (this.eventFirePosition < this.$parent.scrollTop()) {
                if (!this.$target.hasClass(this.options.addClassName)) {
                    this.$target.addClass(this.options.addClassName);
                    this.destroy();
                    return;
                }
            }

            // 最後まで行った場合、まだイベントが起きていなかったらうごかす
            if (this.options.scrollEnd) {
                if (this.$parent.outerHeight() - window.innerHeight <= this.$parent.scrollTop()) {
                    if (!this.$target.hasClass(this.options.addClassName)) {
                        this.$target.addClass(this.options.addClassName);
                        this.destroy();
                        return;
                    }
                }
            }
        }

        /**
         * スクロールイベントで実行するコールバック関数
         */
        scrollHandle(e) {
            if (this.$target.hasClass(this.options.addClassName)) {
                this.destroy();
                return;
            }
            this.fire();
        }

        /**
         * リサイズイベントで実行するコールバック関数
         */
        resizeHandle(e) {
            if (this.resizeTimer) clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => this.setEventPoition(), 100);
        }
    }

    // 対象となる要素を取得して処理を実行
    const $base = $('[data-inview-base]');
    const $target = $('[data-inview]');

    // 要素が取れなかったら終了
    if ($base.length == 0 || $target.length == 0) return;

    // イベント設定
    $target.each((index, elem) => { new Inview($(elem), $base) });
});


$(window).on('load', () => {
    const $before = $('.js-border-before');
    const $after = $('.js-border-after');

    if ($before.length == 0 || $after.length == 0) {
        return;
    }

    function getBeforeHeight() {
        const scrollTop = $('.outer__inner').scrollTop();
        const $topTarget = $('.top-profile');
        const $topBox = $('.top-profile__box');

        const $bottomTarget = $('.top-fanclub');
        const $bottomTitle = $('.top-fanclub__title');

        let offset = 60;
        if ($('.top-profile').hasClass('js-active')) {
            offset = 0;
        }

        const topPosition = scrollTop + $topTarget.offset().top + $topTarget.height();
        const bottomPosition = scrollTop + $bottomTarget.offset().top + $bottomTitle.position().top;
        const height = ($topTarget.height() - $topBox.position().top - $topBox.outerHeight());
        return bottomPosition - topPosition + height + offset;
    }

    function getAfterHeight() {
        const scrollTop = $('.outer__inner').scrollTop();
        const $target = $('.top-goods');
        const $base = $('.top-fanclub__title--img');
        let offset = 190;
        if ($target.hasClass('js-active')) {
            offset = 138;
        }
        return Math.abs((scrollTop + $target.offset().top) - (scrollTop + $base.offset().top)) - offset;
    }

    function setHeight() {
        let beforeHeight = getBeforeHeight();
        $before.css({ 'height': (beforeHeight + 35) + 'px', 'top': -(beforeHeight - 5) + 'px' });

        let afterHeight = getAfterHeight();
        $after.css('height', afterHeight + 'px');
    }

    let timer = null;
    $(window).on('resize', () => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(setHeight, 200);
    });

    setHeight();
});


$(function () {
    const $wrapper = $('.outer__inner');
    const $totop = $('.fanclub-page .totop');
    const activeClass = 'js-active';

    if ($totop.length === 0) {
        return;
    }

    function scrollHandle() {
        if ($wrapper.scrollTop() > 100) {
            $totop.addClass(activeClass);
        } else {
            $totop.removeClass(activeClass);
        }
    }

    $wrapper.on('scroll', scrollHandle);
    scrollHandle();
});